import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// import { useDispatch } from 'react-redux';
// import _ from 'lodash';
// import { useForm } from 'react-hook-form';
// import axios from 'axios';

// import { SmartLink } from '../SmartLink';

import style from './Subscribe.module.scss';

const Subscribe = props => {
    const {
        fieldname,
        placeholder,
        errorText,
        buttonLabel,
        // onHandleSubmit,
    } = props;

    const ciConsenturl = useRef(null);

    // const {
    //     register,
    //     watch,
    //     formState: { errors },
    //     handleSubmit,
    //     setValue,
    // } = useForm();

    const [isDisabled, setIsDisabled] = useState(false);

    // const onSubmit = (data, e) => {
    //     if (data && !isDisabled) {
    //         setIsDisabled(true);
    //     }
    //
    //     if (!_.isEmpty(data.email)) {
    //         data = {
    //             ...data,
    //             email: data.email,
    //         };
    //     }
    //     // onHandleSubmit(data);
    //     axios.post('https://permira-news.com/signup.ashx', data)
    //     .then(function (response) {
    //         console.log(response);
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     });
    // };

    const [errors, setErrors] = useState({ email: '' });

    const validateSignup = e => {
        // e.preventDefault();
        const frm = e.target;
        const emailAddress = Array.from(frm.elements).filter(f => f.name === 'email')[0].value;
        if (emailAddress === '' || !emailAddress.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
            e.preventDefault();
            setErrors({ email: errorText });
        }
        let isError = false;
        if (errors.email.length > 0) {
            isError = true;
        }
        if (!isError && !isDisabled) {
            setIsDisabled(true);
        }
        return !isError;
    };

    useEffect(() => {
        // setValue('ci_consenturl', encodeURI(window.location.href));
        ciConsenturl.current.value = encodeURI(window.location.href);
    });

    return (
        <div className={style.el}>
            <div className={style.wrapper}>
                <form
                    name='signup'
                    id='signup'
                    action='https://permira-news.com/signup.ashx'
                    method='post'
                    autoComplete='off'
                    onSubmit={e => validateSignup(e)}
                >
                    <div className={[style.fieldWrapper, 'fieldWrapper'].join(' ')}>
                        <input type='hidden' name='userid' value='125824' />
                        <input
                            type='hidden'
                            name='SIG44ee6bd7055d5c306076caaad9a0fcf4f92747118692ed2d9897b279658796b8'
                            value=''
                        />
                        <input type='hidden' name='addressbookid' value='29007595' />
                        <input type='hidden' name='ReturnURL' value='' />
                        <input type='hidden' ref={ciConsenturl} name='ci_consenturl' value='' />
                        {/* <input
                            className={[
                                style['form-input'],
                                watch(fieldname),
                            ].join(' ')}
                            {...register(fieldname, {
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                },
                            })}
                            defaultValue=''
                            placeholder={placeholder}
                        /> */}
                        <input
                            type='email'
                            name='email'
                            className={style['form-input']}
                            required
                            aria-required='true'
                            defaultValue=''
                            placeholder={placeholder}
                        />
                    </div>
                    <div className={[style.fieldWrapper, style.noBorder].join(' ')}>
                        <button className={style.submitButton} type='submit'>
                            {buttonLabel}
                        </button>
                    </div>
                </form>
            </div>
            {errors[fieldname] && <div className={style.errorText}>{errorText}</div>}
        </div>
    );
};

Subscribe.propTypes = {
    fieldname: PropTypes.string,
    placeholder: PropTypes.string,
    errorText: PropTypes.string,
    buttonLabel: PropTypes.string,
    onHandleSubmit: PropTypes.func,
};

Subscribe.defaultProps = {};

export default Subscribe;
