// Temporary manual soltion; to be converted to GTM
export function setupLinkedInManual() {
    if (!window['liinsights_load']) {
        const linkedInPartnerId = '3414025';
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(linkedInPartnerId);

        (function (l) {
            if (!l) {
                window.lintrk = function (a, b) {
                    window.lintrk.q.push([a, b]);
                };
                window.lintrk.q = [];
            }
            const s = document.getElementsByTagName('script')[0];
            const b = document.createElement('script');
            b.type = 'text/javascript';
            b.async = true;
            b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
            s.parentNode.insertBefore(b, s);
            window['lintrk']();
        })(window.lintrk);

        window['liinsights_load'] = true;
    }
}
