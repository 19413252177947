import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';
// import { useDispatch } from 'react-redux';

import { SmartLink } from '../SmartLink';
import Subscribe from '../Subscribe';

import style from './Footer.module.scss';

const Footer = (props) => {
    const { title, logoImage, logoMobileImage, logoLink, navigation, subscription, copyrightText, socialMedia } = props;

    const isMobile = useMediaQuery('only screen and (max-width: 599px)');

    return (
        <footer className={style.el} id="footer">
            <div className={style.top}>
                <div className={[style.container, 'container'].join(' ')}>
                    <div className={style.sitemap}>
                        {navigation.map((item, i) => (
                            <div key={i} className={style.sitemapCol}>
                                <SmartLink key={`sitemaplink${i}`} className={style.sitemapLink} href={item.link.url}>
                                    {item.text}
                                </SmartLink>
                            </div>
                        ))}
                    </div>
                    <div className={style.logo}>
                        <SmartLink href={logoLink}>
                            {isMobile && logoMobileImage?.url ? (
                                <img src={logoMobileImage?.url} alt={logoMobileImage?.alt} />
                            ) : (
                                <img src={logoImage.url} alt={logoImage?.alt} />
                            )}
                        </SmartLink>
                    </div>
                </div>
            </div>
            <div className={style.bottom}>
                <div className={[style.container, 'container'].join(' ')}>
                    <div className={style.flexrow}>
                        <h2>{title}</h2>
                        <Subscribe {...subscription} />
                    </div>
                    <div className={[style.flexrow, style.last].join(' ')}>
                        <div className={style.social}>
                            {socialMedia.map((item) => (
                                <SmartLink key={`social${item.icon}`} href={item.link.url}>
                                    <span className={`icon-${item.icon}`} />
                                </SmartLink>
                            ))}
                        </div>
                        <div className={style.copyright}>{copyrightText}</div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    title: PropTypes.string,
    navigation: PropTypes.array,
    logoImage: PropTypes.object,
    logoMobileImage: PropTypes.object,
    logoLink: PropTypes.string,
    subscription: PropTypes.object,
    copyrightText: PropTypes.string,
    socialMedia: PropTypes.array,
};

Footer.defaultProps = {
    logoImage: {
        url: '/images/logo-white.png',
        alt: '',
    },
    title: 'Connect with us',
    subscription: {
        title: 'Connect with us',
        fieldname: 'email',
        placeholder: 'EMAIL ADDRESS',
        errorText: 'please enter your email address',
        buttonLabel: 'SUBMIT',
    },
    copyrightText: '© 2021 Permira I.P Limited',
    navigation: [
        [
            {
                text: 'People',
                link: {
                    url: '/people',
                    target: '',
                },
                items: [
                    {
                        text: 'Meet Our People',
                        link: {
                            url: '/meet-our-people',
                            target: '',
                        },
                    },
                    {
                        text: 'Culture and Values',
                        link: {
                            url: '/culture-and-values',
                            target: '',
                        },
                    },
                    {
                        text: 'Life at Permira',
                        link: {
                            url: '/life-at-permira',
                            target: '',
                        },
                    },
                ],
            },
            {
                text: 'Responsibility',
                link: {
                    url: '/responsibility',
                    target: '',
                },
                items: [
                    {
                        text: 'Investing Responsibly',
                        link: {
                            url: '/investing-responsibly',
                            target: '',
                        },
                    },
                    {
                        text: 'Permira Foundation',
                        link: {
                            url: '/permira-foundation',
                            target: '',
                        },
                    },
                ],
            },
        ],
        [
            {
                text: 'Investing',
                link: {
                    url: '/investing',
                    target: '',
                },
                items: [
                    {
                        text: 'Private Equity Buyout',
                        link: {
                            url: '/private-equity-buyout',
                            target: '',
                        },
                    },
                    {
                        text: 'Private Equity Growth',
                        link: {
                            url: '/private-equity-growth',
                            target: '',
                        },
                    },
                    {
                        text: 'Credit',
                        link: {
                            url: '/credit',
                            target: '',
                        },
                    },
                ],
            },
            {
                text: 'About',
                link: {
                    url: '/about',
                    target: '',
                },
                items: [
                    {
                        text: 'Permira Today',
                        link: {
                            url: '/permira-today',
                            target: '',
                        },
                    },
                    {
                        text: 'Insights',
                        link: {
                            url: '/insights',
                            target: '',
                        },
                    },
                    {
                        text: 'Media',
                        link: {
                            url: '/media',
                            target: '',
                        },
                    },
                ],
            },
        ],
        [
            {
                text: 'Portfolio',
                link: {
                    url: '/portfolio',
                    target: '',
                },
                items: [
                    {
                        text: 'Our Portfolio',
                        link: {
                            url: '/our-portfolio',
                            target: '',
                        },
                    },
                    {
                        text: 'Technology',
                        link: {
                            url: '/technology',
                            target: '',
                        },
                    },
                    {
                        text: 'Consumer',
                        link: {
                            url: '/consumer',
                            target: '',
                        },
                    },
                    {
                        text: 'Services',
                        link: {
                            url: '/services',
                            target: '',
                        },
                    },
                    {
                        text: 'Healthcare',
                        link: {
                            url: '/healthcare',
                            target: '',
                        },
                    },
                ],
            },
        ],
        [
            {
                text: 'Others',
                link: {
                    url: '/others',
                    target: '',
                },
                items: [
                    {
                        text: 'Goverance',
                        link: {
                            url: '/goverance',
                            target: '',
                        },
                    },
                    {
                        text: 'Legal',
                        link: {
                            url: '/legal',
                            target: '',
                        },
                    },
                    {
                        text: 'Privacy Policy',
                        link: {
                            url: '/privacy-policy',
                            target: '',
                        },
                    },
                    {
                        text: 'Cookie Notice',
                        link: {
                            url: '/cookie-notice',
                            target: '',
                        },
                    },
                    {
                        text: 'MSA',
                        link: {
                            url: '/msa',
                            target: '',
                        },
                    },
                    {
                        text: 'PGL',
                        link: {
                            url: '/pgl',
                            target: '',
                        },
                    },
                ],
            },
        ],
    ],
    socialMedia: [
        {
            icon: 'wechat',
            link: {
                target: '',
                url: '#',
            },
        },
        {
            icon: 'vimeo',
            link: {
                target: '_blank',
                url: '#',
            },
        },
        {
            icon: 'youtube',
            link: {
                target: '',
                url: '#',
            },
        },
        {
            icon: 'twitter',
            link: {
                target: '',
                url: '#',
            },
        },
        {
            icon: 'linkedin',
            link: {
                target: '',
                url: '#',
            },
        },
    ],
};

export default Footer;
