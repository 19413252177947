export const setGTMconsent = enableStorage => {
    if (typeof window !== 'undefined') {
        if (enableStorage) {
            window.gtag('consent', 'update', {
                ad_storage: 'granted',
                analytics_storage: 'granted',
            });
        } else {
            window.gtag('consent', 'update', {
                ad_storage: 'denied',
                analytics_storage: 'denied',
            });
        }
    }
};

export const initGTMconsent = () => {
    if (typeof window !== 'undefined') {
        window.gtag('consent', 'default', {
            ad_storage: 'denied',
            analytics_storage: 'denied',
            functionality_storage: 'denied',
            personalization_storage: 'denied',
            security_storage: 'denied',
            wait_for_update: 500,
        });
    }
};
